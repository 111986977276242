<template>
    <div class="switchPage">
          <div class="title-hang">
      <div class="fl">
        <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
      </div>
      <div class="zhuangshizb fl"></div>
      <div class="zhuangshizj fl">{{projectData.project}}_切换画面</div>
      <div class="zhuangshiyb fl"></div>
      <div class="fr">
        <div class="fl">
          <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
        </div>
      </div>
    </div>
    <div class="Switchpage-top flex ">
        <div class="top-flex1">
            <div class="top-tit">切换开关</div>
        <div style="height:100%" class="top-flex1-1 flex align-cen justify-content-cen">
         <div class="top-data flex align-cen justify-content-cen">
        <div class="flex1-list-text">煤气总管切换</div>
        <div class="text-status"
        :style= "{background:(infoList.V_SOUREC.DB_01_B ?'red':'#29DFAE')}"
        >
        </div>
        </div>
        <div class="top-data  top-data1 flex align-cen justify-content-cen">
        <div class="flex1-list-text">1#热风炉切换</div>
         <div class="text-status"
        :style= "{background:(infoList.V_SOUREC.DB_11_B1 ?'red':'#29DFAE')}"
        >
        </div>
        </div>
         <div class="top-data  top-data1 flex align-cen justify-content-cen">
        <div class="flex1-list-text">助燃风总管1切换</div>
        <div class="text-status"
        :style= "{background:(infoList.V_SOUREC.DB_02_B ?'red':'#29DFAE')}"
        >
        </div>
        </div>
          <div class="top-data  top-data1 flex align-cen justify-content-cen">
        <div class="flex1-list-text">2#热风炉切换</div>
        <div class="text-status"
        :style= "{background:(infoList.V_SOUREC.DB_11_B2 ?'red':'#29DFAE')}"
        >
        </div>
        </div>
        <div class="top-data  top-data1 flex align-cen justify-content-cen">
        <div class="flex1-list-text">助燃风总管2切换</div>
         <div class="text-status"
        :style= "{background:(infoList.V_SOUREC.DB_03_B ?'red':'#29DFAE')}"
        >
        </div>
        </div>
          <div class="top-data  top-data1 flex align-cen justify-content-cen">
        <div class="flex1-list-text">3#热风炉切换</div>
         <div class="text-status"
        :style= "{background:(infoList.V_SOUREC.DB_11_B3 ?'red':'#29DFAE')}"
        >
        </div>
        </div>
        <div class="top-data  top-data1 flex align-cen justify-content-cen">
        </div>
          <div class="top-data  top-data1 flex align-cen justify-content-cen">
        <div class="flex1-list-text">4#热风炉切换</div>
          <div class="text-status"
        :style= "{background:(infoList.V_SOUREC.DB_11_B4 ?'red':'#29DFAE')}"
        >
        </div>
        </div>
            <div class="top-data  top-data1 flex align-cen justify-content-cen">
        <div class="flex1-list-text">PLC控制</div>
         <div class="text-status"
        :style= "{background:(value1 ?'red':'#29DFAE')}"
        >
        </div>
        </div>
            <div class="top-data  top-data1 flex align-cen justify-content-cen">
        <div class="flex1-list-text">BCS控制</div>
          <div class="text-status"
        :style= "{background:(value2 ?'red':'#29DFAE')}"
        >
        </div>
        </div>
            </div>
        </div>
        <div class="top-flex1 top-flex2 flex align-cen justify-content-cen">
            <div class="top-tit">重要参数</div>
            <div class="flex2-row">
                <div class="flex2-row-list flex">
                    <div class="list-l">
                        <div class="list-number" @click="toCompon(2,'BFW','V_SOUREC','BFW_WF','本班平均热风风温')"
                        @dblclick="Cclick(infoList.V_SOUREC.BFW,'BFW', 'V_SOUREC', 'BFW_WF')"
                        >{{infoList.V_SOUREC.BFW}}</div>
                        <div class="list-text">本班平均风温</div>
                    </div>
                    <div class="list-l list-r">
                        <div class="list-number" @click="toCompon(2,'BFW_L','V_SOUREC','BFW_L_WF','上班平均热风风温')"
                        @dblclick="Cclick(infoList.V_SOUREC.BFW_L,'BFW_L', 'V_SOUREC', 'BFW_L_WF')"
                        >{{infoList.V_SOUREC.BFW_L}}</div>
                        <div class="list-text">上班平均风温</div>
                    </div>
                </div>
                 <div class="flex2-row-list flex2-row-list1 flex">
                    <div class="list-l">
                        <div class="list-number" @click="toCompon(2,'BMQLJ','V_SOUREC','BMQLJ_WF','本班煤气累计')"
                        @dblclick="Cclick(infoList.V_SOUREC.BMQLJ,'BMQLJ', 'V_SOUREC', 'BMQLJ_WF')"
                        >{{infoList.V_SOUREC.BMQLJ}}</div>
                        <div class="list-text">本班煤气总量</div>
                    </div>
                    <div class="list-l list-r">
                        <div class="list-number" @click="toCompon(2,'BMQLJ_L','V_SOUREC','BMQLJ_L_WF','上班煤气累计')"
                        @dblclick="Cclick(infoList.V_SOUREC.BMQLJ_L,'BMQLJ_L', 'V_SOUREC', 'BMQLJ_L_WF')"
                        >{{infoList.V_SOUREC.BMQLJ_L}}</div>
                        <div class="list-text">上班煤气总量</div>
                    </div>
                </div>
                   <div class="flex2-row-list flex2-row-list1 flex">
                    <div class="list-l">
                        <div class="list-number" @click="toCompon(2,'BSFLJ','V_SOUREC','BSFLJ_WF','本班送风累计')"
                         @dblclick="Cclick(infoList.V_SOUREC.BSFLJ,'BSFLJ', 'V_SOUREC', 'BSFLJ_WF')"
                        >{{infoList.V_SOUREC.BSFLJ}}</div>
                        <div class="list-text">本班送风总量</div>
                    </div>
                    <div class="list-l list-r">
                        <div class="list-number" @click="toCompon(2,'BSFLJ_L','V_SOUREC','BSFLJ_L_WF','上班送风累计')"
                        @dblclick="Cclick(infoList.V_SOUREC.BSFLJ_L,'BSFLJ_L', 'V_SOUREC', 'BSFLJ_L_WF')"
                        >{{infoList.V_SOUREC.BSFLJ_L}}</div>
                        <div class="list-text">上班送风总量</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-flex1 top-flex2 top-flex3">
            <div class="top-tit">拱顶信号</div>
            <div class="flex2-row1 flex align-cen">
                <div class="list-row" @click="toIpt(infoList.V_OTHER1.SEL110, '1#炉用顶温','SEL110','V_OTHER1')">
                   1#炉用顶温{{infoList.V_OTHER1.SEL210 ? '2' : '1'}}
                </div>
                <div class="list-row list-row1" @click="toIpt(infoList.V_OTHER1.SEL210, '2#炉用顶温','SEL210','V_OTHER1')">
                   2#炉用顶温{{infoList.V_OTHER1.SEL210 ? '2' : '1'}}
                </div>
               
                <div class="list-row" @click="toIpt(infoList.V_OTHER1.SEL310, '3#炉用顶温','SEL310','V_OTHER1')">
                   3#炉用顶温{{infoList.V_OTHER1.SEL310 ? '2' : '1'}}
                </div>
                 <div class="list-row list-row1" @click="toIpt(infoList.V_OTHER1.SEL410, '4#炉用顶温','SEL410','V_OTHER1')">
                   4#炉用顶温{{infoList.V_OTHER1.SEL410 ? '2' : '1'}}
                </div>
            </div>
            <div class="flex2-row2 flex align-cen">
            <div class="row2-1">
                <div class="yuan1" @click="toCompon(2,'HEARTS_B','V_SOUREC','HEARTS_B_WF','原系统产生心跳数据')"
                @dblclick="Cclick(infoList.V_SOUREC.HEARTS_B,'HEARTS_B', 'V_SOUREC', 'HEARTS_B_WF')"
                >
                    <div class="text">BCS</div>
                    <div class="number">{{infoList.V_SOUREC.HEARTS_B}}</div>
                </div>
            </div>
             <div class="row2-1 row2-2">
                <div class="yuan1" @click="toCompon(2,'HEARTR_B','V_SOUREC','HEARTR_B_B_WF')"
                @dblclick="Cclick(infoList.V_SOUREC.HEARTR_B,'HEARTR_B', 'V_SOUREC', 'HEARTR_B_B_WF')"
                >
                    <div class="text">DSC</div>
                    <div class="number">{{infoList.V_SOUREC.HEARTR_B}}</div>
                </div>
            </div>
            <div class="row2-3">
                <div class="top-flex3-two-stuats"
               :style= "[{color:(infoList.V_ALARM.P03ALM?'red':'#22ca85')},
               {background:(infoList.V_ALARM.P03ALM?'rgba(255,0,0,.2)':'rgba(51, 255, 204, 0.2)')},
               {border:(infoList.V_ALARM.P03ALM?'1px solid red':'1px solid #22ca85')}
               ]"
          >
            {{infoList.V_ALARM.P03ALM ? '通讯故障' :'通讯正常'}}
          </div>
            </div>
            </div>
        </div>
        </div>
    <div class="Switchpage-con flex align-cen">
        <div class="con-row1 flex">
            <div class="con-row1-l">
                <div>
                    <p>1#</p>
                    <p>2#</p>
                    <p>3#</p>
                    <p>4#</p>
                </div>
            </div>
            <div class="con-row1-r">
                <div class="r-tit">煤气流量上限设定</div>
                <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(2000,infoList.V_HLLIM.HLLIMH11,'HLLIMH11','V_HLLIM') : ''">-2000</div>
          <div @click="isDisable ? numMin(200,infoList.V_HLLIM.HLLIMH11,'HLLIMH11','V_HLLIM') : ''">-200</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_HLLIM.HLLIMH11, '1#煤气流量上限设定','HLLIMH11','V_HLLIM')">
            {{infoList.V_HLLIM.HLLIMH11}}
          </div>
          <div @click="isDisable ? numAdd(200,infoList.V_HLLIM.HLLIMH11,'HLLIMH11','V_HLLIM') : ''">+200</div>
          <div @click="isDisable ? numAdd(2000,infoList.V_HLLIM.HLLIMH11,'HLLIMH11','V_HLLIM') : ''">+2000</div>
        </div>
          <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(2000,infoList.V_HLLIM.HLLIMH21,'HLLIMH21','V_HLLIM') : ''">-2000</div>
          <div @click="isDisable ? numMin(200,infoList.V_HLLIM.HLLIMH21,'HLLIMH21','V_HLLIM') : ''">-200</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_HLLIM.HLLIMH21, '2#煤气流量上限设定','HLLIMH21','V_HLLIM')">{{infoList.V_HLLIM.HLLIMH21}}</div>
          <div @click="isDisable ? numAdd(200,infoList.V_HLLIM.HLLIMH21,'HLLIMH21','V_HLLIM') : ''">+200</div>
          <div@click="isDisable ? numAdd(2000,infoList.V_HLLIM.HLLIMH21,'HLLIMH21','V_HLLIM') : ''">+2000</div>
        </div>
              <div class="Switchpage-cen-flex-list flex justify-content-cen">
         <div @click="isDisable ? numMin(2000,infoList.V_HLLIM.HLLIMH31,'HLLIMH31','V_HLLIM') : ''">-2000</div>
          <div @click="isDisable ? numMin(200,infoList.V_HLLIM.HLLIMH31,'HLLIMH31','V_HLLIM') : ''">-200</div>
          <div class="Special"
               
               @click="toIpt(infoList.V_HLLIM.HLLIMH31, '3#煤气流量上限设定','HLLIMH31','V_HLLIM')">{{infoList.V_HLLIM.HLLIMH31}}</div>
          <div @click="isDisable ? numAdd(200,infoList.V_HLLIM.HLLIMH31,'HLLIMH31','V_HLLIM') : ''">+200</div>
          <div@click="isDisable ? numAdd(2000,infoList.V_HLLIM.HLLIMH31,'HLLIMH31','V_HLLIM') : ''">+2000</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(2000,infoList.V_HLLIM.HLLIMH41,'HLLIMH41','V_HLLIM') : ''">-2000</div>
          <div @click="isDisable ? numMin(200,infoList.V_HLLIM.HLLIMH41,'HLLIMH41','V_HLLIM') : ''">-200</div>
          <div class="Special"
              
               @click="toIpt(infoList.V_HLLIM.HLLIMH41, '4#煤气流量上限设定','HLLIMH41','V_HLLIM')">{{infoList.V_HLLIM.HLLIMH41}}</div>
          <div @click="isDisable ? numAdd(200,infoList.V_HLLIM.HLLIMH41,'HLLIMH41','V_HLLIM') : ''">+200</div>
          <div@click="isDisable ? numAdd(2000,infoList.V_HLLIM.HLLIMH41,'HLLIMH41','V_HLLIM') : ''">+2000</div>
        </div>
            </div>
        </div>
        <div class="con-row2">
            <div class="con-row1-r">
                <div class="r-tit">拱顶保护温度</div>
                        <div class="Switchpage-cen-flex-list flex justify-content-cen" style="font-size:1.8vh">
          <div @click="isDisable ? numMin(5,infoList.V_SOUREC.GDWDH_B1,'GDWDH_B1','V_SOUREC') : ''">-5</div>
          <div @click="isDisable ? numMin(1,infoList.V_SOUREC.GDWDH_B1,'GDWDH_B1','V_SOUREC') : ''">-1</div>
          <div class="Special"
              
               @click="toIpt(infoList.V_SOUREC.GDWDH_B1, '1#拱顶保护温度','GDWDH_B1','V_SOUREC')">
            {{infoList.V_SOUREC.GDWDH_B1}}
          </div>
          <div @click="isDisable ? numAdd(1,infoList.V_SOUREC.GDWDH_B1,'GDWDH_B1','V_SOUREC') : ''">+1</div>
          <div @click="isDisable ? numAdd(5,infoList.V_SOUREC.GDWDH_B1,'GDWDH_B1','V_SOUREC') : ''">+5</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(5,infoList.V_SOUREC.GDWDH_B2,'GDWDH_B2','V_SOUREC') : ''">-5</div>
          <div @click="isDisable ? numMin(1,infoList.V_SOUREC.GDWDH_B2,'GDWDH_B2','V_SOUREC') : ''">-1</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SOUREC.GDWDH_B2, '2#拱顶保护温度','V_SOUREC','GDWDH_B2')">
            {{infoList.V_SOUREC.GDWDH_B2}}
          </div>
         <div @click="isDisable ? numAdd(1,infoList.V_SOUREC.GDWDH_B2,'GDWDH_B2','V_SOUREC') : ''">+1</div>
         <div @click="isDisable ? numAdd(5,infoList.V_SOUREC.GDWDH_B2,'GDWDH_B2','V_SOUREC') : ''">+5</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
           <div @click="isDisable ? numMin(5,infoList.V_SOUREC.GDWDH_B3,'GDWDH_B3','V_SOUREC') : ''">-5</div>
          <div @click="isDisable ? numMin(1,infoList.V_SOUREC.GDWDH_B3,'GDWDH_B3','V_SOUREC') : ''">-1</div>
          <div class="Special"
              
               @click="toIpt(infoList.V_SOUREC.GDWDH_B3, '3#拱顶保护温度','GDWDH_B3','V_SOUREC')">
            {{infoList.V_SOUREC.GDWDH_B3}}
          </div>
          <div @click="isDisable ? numAdd(1,infoList.V_SOUREC.GDWDH_B3,'GDWDH_B3','V_SOUREC') : ''">+1</div>
         <div @click="isDisable ? numAdd(5,infoList.V_SOUREC.GDWDH_B3,'GDWDH_B3','V_SOUREC') : ''">+5</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
           <div @click="isDisable ? numMin(5,infoList.V_SOUREC.GDWDH_B4,'GDWDH_B4','V_SOUREC') : ''">-5</div>
          <div @click="isDisable ? numMin(1,infoList.V_SOUREC.GDWDH_B4,'GDWDH_B4','V_SOUREC') : ''">-1</div>
          <div class="Special"
              
               @click="toIpt(infoList.V_SOUREC.GDWDH_B4, '4#拱顶保护温度','GDWDH_B4','V_SOUREC')">
            {{infoList.V_SOUREC.GDWDH_B4}}
          </div>
           <div @click="isDisable ? numAdd(1,infoList.V_SOUREC.GDWDH_B4,'GDWDH_B4','V_SOUREC') : ''">+1</div>
         <div @click="isDisable ? numAdd(5,infoList.V_SOUREC.GDWDH_B4,'GDWDH_B4','V_SOUREC') : ''">+5</div>
        </div>
            </div>
            
        </div>
        <div class="con-row3">
              <div class="con-row1-r">
                  <div class="r-tit">基本风煤比</div>
                    <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(0.01,infoList.V_SOUREC.JBFMB_B1,'JBFMB_B1','V_SOUREC') : ''">-0.01</div>
          <div class="Special"
              
               @click="toIpt(infoList.V_SOUREC.JBFMB_B1, '1#基本风煤比','JBFMB_B1','V_SOUREC')">
            {{infoList.V_SOUREC.JBFMB_B1}}
          </div>
          <div @click="isDisable ? numAdd(0.01,infoList.V_SOUREC.JBFMB_B1,'JBFMB_B1','V_SOUREC') : ''">+0.01</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
           <div @click="isDisable ? numMin(0.01,infoList.V_SOUREC.JBFMB_B2,'JBFMB_B2','V_SOUREC') : ''">-0.01</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SOUREC.JBFMB_B2, '2#基本风煤比','JBFMB_B2','V_SOUREC')">
            {{infoList.V_SOUREC.JBFMB_B2}}
          </div>
         <div @click="isDisable ? numAdd(0.01,infoList.V_SOUREC.JBFMB_B2,'JBFMB_B2','V_SOUREC') : ''">+0.01</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
           <div @click="isDisable ? numMin(0.01,infoList.V_SOUREC.JBFMB_B3,'JBFMB_B3','V_SOUREC') : ''">-0.01</div>
          <div class="Special"
               
               @click="toIpt(infoList.V_SOUREC.JBFMB_B3, '3#基本风煤比','JBFMB_B3','V_SOUREC')">
            {{infoList.V_SOUREC.JBFMB_B3}}
          </div>
          <div @click="isDisable ? numAdd(0.01,infoList.V_SOUREC.JBFMB_B3,'JBFMB_B3','V_SOUREC') : ''">+0.01</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(0.01,infoList.V_SOUREC.JBFMB_B4,'JBFMB_B4','V_SOUREC') : ''">-0.01</div>
          <div class="Special"
               
               @click="toIpt(infoList.V_SOUREC.JBFMB_B4, '4#基本风煤比','JBFMB_B4','V_SOUREC')">
            {{infoList.V_SOUREC.JBFMB_B4}}
          </div>
          <div @click="isDisable ? numAdd(0.01,infoList.V_SOUREC.JBFMB_B4,'JBFMB_B4','V_SOUREC') : ''">+0.01</div>
        </div>
              </div>
        </div>
    </div>
     <div class="Switchpage-con Switchpage-bot flex align-cen">
            <div class="con-row1 flex">
            <div class="con-row1-l">
                <div>
                    <p>1#</p>
                    <p>2#</p>
                    <p>3#</p>
                    <p>4#</p>
                </div>
            </div>
            <div class="con-row1-r">
             <div class="r-tit">烧炉时间设定</div>
             <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(3,infoList.V_SP.SP11,'SP11','V_SP') : ''">-3</div>
          <div @click="isDisable ? numMin(1,infoList.V_SP.SP11,'SP11','V_SP') : ''">-1</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SP.SP11, '1#烧炉时间设定','SP11','V_SP')">
            {{infoList.V_SP.SP11}}
          </div>
          <div  @click="isDisable ? numAdd(1,infoList.V_SP.SP11,'SP11','V_SP') : ''">+1</div>
          <div @click="isDisable ? numAdd(3,infoList.V_SP.SP11,'SP11','V_SP') : ''">+3</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
           <div @click="isDisable ? numMin(3,infoList.V_SP.SP21,'SP21','V_SP') : ''">-3</div>
          <div @click="isDisable ? numMin(1,infoList.V_SP.SP21,'SP21','V_SP') : ''">-1</div>
          <div class="Special"
            
               @click="toIpt(infoList.V_SP.SP21, '2#烧炉时间设定','SP21','V_SP')">
            {{infoList.V_SP.SP21}}
          </div>
          <div  @click="isDisable ? numAdd(1,infoList.V_SP.SP21,'SP21','V_SP') : ''">+1</div>
          <div @click="isDisable ? numAdd(3,infoList.V_SP.SP21,'SP21','V_SP') : ''">+3</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(3,infoList.V_SP.SP31,'SP31','V_SP') : ''">-3</div>
          <div @click="isDisable ? numMin(1,infoList.V_SP.SP31,'SP31','V_SP') : ''">-1</div>
          <div class="Special"
            
               @click="toIpt(infoList.V_SP.SP31, '3#烧炉时间设定','SP31','V_SP')">
            {{infoList.V_SP.SP31}}
          </div>
           <div  @click="isDisable ? numAdd(1,infoList.V_SP.SP31,'SP31','V_SP') : ''">+1</div>
          <div @click="isDisable ? numAdd(3,infoList.V_SP.SP31,'SP31','V_SP') : ''">+3</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(3,infoList.V_SP.SP41,'SP41','V_SP') : ''">-3</div>
          <div @click="isDisable ? numMin(1,infoList.V_SP.SP41,'SP41','V_SP') : ''">-1</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SP.SP41, '4#烧炉时间设定','SP41','V_SP')">
            {{infoList.V_SP.SP41}}
          </div>
           <div  @click="isDisable ? numAdd(1,infoList.V_SP.SP41,'SP41','V_SP') : ''">+1</div>
          <div @click="isDisable ? numAdd(3,infoList.V_SP.SP41,'SP41','V_SP') : ''">+3</div>
        </div>
            </div>
            
        </div>
        <div class="con-row2 flex">
             <div class="con-row1-r">
             <div class="r-tit">废气温度设定</div>
                     <div class="Switchpage-cen-flex-list flex justify-content-cen">
           <div @click="isDisable ? numMin(3,infoList.V_SOUREC.FQSP_B1,'FQSP_B1','V_SOUREC') : ''">-3</div>
          <div @click="isDisable ? numMin(1,infoList.V_SOUREC.FQSP_B1,'FQSP_B1','V_SOUREC') : ''">-1</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SOUREC.FQSP_B1, '1#废弃温度设定','FQSP_B1','V_SOUREC')">
            {{infoList.V_SOUREC.FQSP_B1}}
          </div>
           <div @click="isDisable ? numAdd(3,infoList.V_SOUREC.FQSP_B1,'FQSP_B1','V_SOUREC') : ''">+1</div>
          <div @click="isDisable ? numAdd(1,infoList.V_SOUREC.FQSP_B1,'FQSP_B1','V_SOUREC') : ''">+3</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(3,infoList.V_SOUREC.FQSP_B2,'FQSP_B2','V_SOUREC') : ''">-3</div>
          <div @click="isDisable ? numMin(1,infoList.V_SOUREC.FQSP_B2,'FQSP_B2','V_SOUREC') : ''">-1</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SOUREC.FQSP_B2, '2#废弃温度设定','FQSP_B2','V_SOUREC')">
            {{infoList.V_SOUREC.FQSP_B2}}
          </div>
         <div @click="isDisable ? numAdd(3,infoList.V_SOUREC.FQSP_B2,'FQSP_B2','V_SOUREC') : ''">+1</div>
          <div @click="isDisable ? numAdd(1,infoList.V_SOUREC.FQSP_B2,'FQSP_B2','V_SOUREC') : ''">+3</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
            <div @click="isDisable ? numMin(3,infoList.V_SOUREC.FQSP_B3,'FQSP_B3','V_SOUREC') : ''">-3</div>
          <div @click="isDisable ? numMin(1,infoList.V_SOUREC.FQSP_B3,'FQSP_B3','V_SOUREC') : ''">-1</div>
          <div class="Special"
          
               @click="toIpt(infoList.V_SOUREC.FQSP_B3, '3#废弃温度设定','FQSP_B3','V_SOUREC')">
            {{infoList.V_SOUREC.FQSP_B3}}
          </div>
            <div @click="isDisable ? numAdd(3,infoList.V_SOUREC.FQSP_B3,'FQSP_B3','V_SOUREC') : ''">+1</div>
          <div @click="isDisable ? numAdd(1,infoList.V_SOUREC.FQSP_B3,'FQSP_B3','V_SOUREC') : ''">+3</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
            <div @click="isDisable ? numMin(3,infoList.V_SOUREC.FQSP_B4,'FQSP_B4','V_SOUREC') : ''">-3</div>
          <div @click="isDisable ? numMin(1,infoList.V_SOUREC.FQSP_B4,'FQSP_B4','V_SOUREC') : ''">-1</div>
          <div class="Special"
              
               @click="toIpt(infoList.V_SOUREC.FQSP_B4, '4#废弃温度设定','FQSP_B4','V_SOUREC')">
            {{infoList.V_SOUREC.FQSP_B4}}
          </div>
            <div @click="isDisable ? numAdd(3,infoList.V_SOUREC.FQSP_B4,'FQSP_B4','V_SOUREC') : ''">+1</div>
          <div @click="isDisable ? numAdd(1,infoList.V_SOUREC.FQSP_B4,'FQSP_B4','V_SOUREC') : ''">+3</div>
        </div>
             </div>
        </div>
        <div class="con-row2 con-row3 flex">
              <div class="con-row1-r">
             <div class="r-tit">煤气量干预</div>
              <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(1000,infoList.V_SOUREC.GYMQL_B1,'GYMQL_B1','V_SOUREC') : ''">-1000</div>
          <div class="Special"
            
               @click="toIpt(infoList.V_SOUREC.GYMQL_B1, '1#煤气量干预','GYMQL_B1','V_SOUREC')">
            {{infoList.V_SOUREC.GYMQL_B1}}
          </div>
          <div @click="isDisable ? numAdd(1000,infoList.V_SOUREC.GYMQL_B1,'GYMQL_B1','V_SOUREC') : ''">+1000</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(1000,infoList.V_SOUREC.GYMQL_B2,'GYMQL_B2','V_SOUREC') : ''">-1000</div>
          <div class="Special"
              
               @click="toIpt(infoList.V_SOUREC.GYMQL_B2, '2#煤气量干预','GYMQL_B2','V_SOUREC')">
            {{infoList.V_SOUREC.GYMQL_B2}}
          </div>
           <div @click="isDisable ? numAdd(1000,infoList.V_SOUREC.GYMQL_B2,'GYMQL_B2','V_SOUREC') : ''">+1000</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(1000,infoList.V_SOUREC.GYMQL_B3,'GYMQL_B3','V_SOUREC') : ''">-1000</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SOUREC.GYMQL_B3, '3#煤气量干预','GYMQL_B3','V_SOUREC')">
            {{infoList.V_SOUREC.GYMQL_B3}}
          </div>
          <div @click="isDisable ? numAdd(1000,infoList.V_SOUREC.GYMQL_B3,'GYMQL_B3','V_SOUREC') : ''">+1000</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(1000,infoList.V_SOUREC.GYMQL_B4,'GYMQL_B4','V_SOUREC') : ''">-1000</div>
          <div class="Special"
           
               @click="toIpt(infoList.V_SOUREC.GYMQL_B4, '4#煤气量干预','GYMQL_B4','V_SOUREC')">
            {{infoList.V_SOUREC.GYMQL_B4}}
          </div>
          <div @click="isDisable ? numAdd(1000,infoList.V_SOUREC.GYMQL_B4,'GYMQL_B4','V_SOUREC') : ''">+1000</div>
        </div>
              </div>
        </div>
        <div class="con-row2 con-row3 flex">
               <div class="con-row1-r">
             <div class="r-tit">含氧量设定</div>
                  <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(0.1,infoList.V_SOUREC.ZJZQ_B1,'ZJZQ_B1','V_SOUREC') : ''">-0.1</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SOUREC.ZJZQ_B1, '1#含氧量设定','ZJZQ_B1','V_SOUREC')">
            {{infoList.V_SOUREC.ZJZQ_B1}}
          </div>
          <div @click="isDisable ? numAdd(0.1,infoList.V_SOUREC.ZJZQ_B1,'ZJZQ_B1','V_SOUREC') : ''">+0.1</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
         <div @click="isDisable ? numMin(0.1,infoList.V_SOUREC.ZJZQ_B2,'ZJZQ_B2','V_SOUREC') : ''">-0.1</div>
          <div class="Special"
             
               @click="toIpt(infoList.V_SOUREC.ZJZQ_B2, '2#含氧量设定','ZJZQ_B2','V_SOUREC')">
            {{infoList.V_SOUREC.ZJZQ_B2}}
          </div>
          <div @click="isDisable ? numAdd(0.1,infoList.V_SOUREC.ZJZQ_B2,'ZJZQ_B2','V_SOUREC') : ''">+0.1</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
          <div @click="isDisable ? numMin(0.1,infoList.V_SOUREC.ZJZQ_B3,'ZJZQ_B3','V_SOUREC') : ''">-0.1</div>
          <div class="Special"
           
               @click="toIpt(infoList.V_SOUREC.ZJZQ_B3, '3#含氧量设定','ZJZQ_B3','V_SOUREC')">
            {{infoList.V_SOUREC.ZJZQ_B3}}
          </div>
           <div @click="isDisable ? numAdd(0.1,infoList.V_SOUREC.ZJZQ_B3,'ZJZQ_B3','V_SOUREC') : ''">+0.1</div>
        </div>
        <div class="Switchpage-cen-flex-list flex justify-content-cen">
         <div @click="isDisable ? numMin(0.1,infoList.V_SOUREC.ZJZQ_B4,'ZJZQ_B4','V_SOUREC') : ''">-0.1</div>
          <div class="Special"
           
               @click="toIpt(infoList.V_SOUREC.ZJZQ_B4, '4#含氧量设定','ZJZQ_B4','V_SOUREC')">
            {{infoList.V_SOUREC.ZJZQ_B4}}
          </div>
          <div @click="isDisable ? numAdd(0.1,infoList.V_SOUREC.ZJZQ_B4,'ZJZQ_B4','V_SOUREC') : ''">+0.1</div>
        </div>
               </div>
        </div>
     </div>
    <inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
     <Historical v-if="isHshow" @sendStatus = 'isClose1' :historyname = 'historyname' :node='node' :Lkname='Lkname' :chName="chName" :infoList = 'infoList'></Historical>
    </div>
    
</template>
<script>
import Historical from "@/components/Historical";
import inputVal from "@/components/inputVal"   //输入框组件
import { downAction } from "@/api/index/index"
export default {
    name: "switchPage",
    components: {Historical,inputVal},
        props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
    data: () => {
		return {
			chName: '',
            value1:false,
            value2:true,
            isComShow:false,
            isHshow:false,
            isClose:false,
            historyname:'',
            node:'',
            Lkname:'',
            projectData:{},
            isDisable:true,
            grouptime:null,
            spotArr:[],
		}
	},
        watch: {
    infoList: {
      handler (n, o) {
       this.infoList = n
      },
      deep: true // 深度监听父组件传过来对象变化
    },
   },
       created(){
     this.projectData = JSON.parse(localStorage.getItem("deviceType"))
     this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
   },
   methods:{
       Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
            DataJson(data){
      let strNode = data[0]
      let strMark = data[1]
        this.$nextTick(() => {
       this.infoList[strNode][strMark] = data[2]     
      });
    },
      //  参数减
        numMin(addnum,nownum,mark,node){
          this.isDisable = true
          let newnum = parseFloat(nownum) - parseFloat(addnum)
           this.infoList[node][mark] = newnum.toFixed(2) //保留两位小数
              	downAction({
                device:this.projectData.project,
                mark:mark,
                node:node,
                value: this.infoList[node][mark],
                cp_type:this.projectData.type
				})
            .then(res => {
              if(res.info.res == 1){
                this.isDisable = true
              }else{
                this.$message.error('出错了');
                 this.isDisable = true
                 this.infoList[node][mark] = nownum
              }
            })
				.catch(err => {
          this.$message.error('下置失败');
           this.isDisable = true
           this.infoList[node][mark] = nownum
				})
        },
        //  参数加
        numAdd(addnum,nownum,mark,node){
          this.isDisable = false
          let newnum = parseFloat(addnum) + parseFloat(nownum)
          this.infoList[node][mark] = newnum.toFixed(2) //保留两位小数
                	downAction({
                device:this.projectData.project,
                mark:mark,
                node:node,
                value: this.infoList[node][mark],
                cp_type:this.projectData.type
				})
            .then(res => {
              if(res.info.res == 1){
                this.isDisable = true
              }else{
                this.$message.error('出错了');
                 this.isDisable = true
                 this.infoList[node][mark] = nownum
              }
            })
				.catch(err => {
          this.$message.error('下置失败');
           this.isDisable = true
           this.infoList[node][mark] = nownum
				})
        },
    //    打开历史趋势
     toCompon(key,name,name2,name3,name4){
      this.isIndex = key
      this.historyname = name
      this.node = name2
      this.Lkname = name3
      	clearTimeout(this.grouptime);
				this.grouptime=setTimeout(() =>{
        switch(key){
              case 0:
                return this.isMshow = true
              case 1 :
                return  this.isFshow = true
              case 2:
                return  this.isHshow = true, this.chName = name4
            }
        },300)
    
    },
        isClose1(){
               this.isHshow = false
     },
       toIpt(data,name,historyname,node){
            this.$refs.inputVal.open(data,name,historyname,node)
        },
   },

}
</script>
<style lang="scss" scoped>
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width: 96vw;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
  }
   .zhuangshizb {
    width: 14vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-left: 3.5vw;
    margin-top: 1.5vh;
  }
  .zhuangshizj {
    width: 26vw;
    height: 4vh;
    line-height:4vh;
    margin-top:1vh;
    text-align: center;
    background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    margin-left: 5vw;
    font-family: MicrosoftYaHei;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }
  .zhuangshiyb {
    width: 14vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    margin-left: 5vw;
    margin-top: 1.5vh;
  }
   .evaluate-header{
        height: 8vh;
    // background: #5DCEF3;
        line-height: 8vh;
        //  background: #58cdf4;
        //  line-height: 50px;
         text-align: center;
         color: #0ef5fd;
         font-size: 3vh;
         position: relative;
         border-radius: 5px 5px 0 0;
     }
     .Switchpage-top{
         width: 96vw;
         height: 28vh;
         margin-top: 2vh;
		 margin-left: 1.5vw;
         .top-flex1{
             width: 31vw;
             height: calc(100% - 5vh);
             padding: 2.5vh 0;
            background: url("~@/assets/images/page_img1.png") no-repeat 0 0;
            background-size: 100% 100%;
            border-style: solid;
            border-width: 0vw;
            border-image-source: linear-gradient(180deg, 
                #000000 0%, 
                #23526d 100%);
            border-image-slice: 1;
            position: relative;
            .top-tit{
                color: #ffffff;
                font-size: 2vh;
                position: absolute;
                top: 0;
                left: 2.5vw;
            }
         }
         .top-flex2{
             margin-left: 1vw;
             .flex2-row{
                 width: 22vw;
                 height: 20vh;
                 margin-top: 2vh;
                 .flex2-row-list1{
                     margin-top: 1vh;
                 }
                 .list-r{
                     margin-left: auto;
                 }
                 .list-l{
                     width: 7.5vw;
                     height: 4vh;
                    background: url("~@/assets/images/page_img2.png") no-repeat 0 0;
                    background-size: 100% 100%;
                     padding: 1vh 0;
                     padding-left: 1.5vw;
                     line-height: 2.2vh;
                    .list-number{
                        color: #fff;
                        font-size: 1.7vh;
                        cursor: pointer;
                       
                    } 
                    .list-text{
                       color: #6acafb;
                       font-size: 1vw;
                    }
                 }
             }
             .flex2-row1{
                  width: 22vw;
                 height: 9vh;
                 margin: 0 auto;
                 flex-wrap: wrap;
                 margin-top: 2.5vh;
                 .list-row{
                     width: 10vw;
                     height: 4vh;
                     background: rgba(102,136,152,.2);
                     text-align: center;
                     font-size: 1.7vh;
                     line-height: 4vh;
                     color: #8aeaff;
                     cursor: pointer;
                 }
                 .list-row1{
                     margin-left: auto;
                 }
             }
             .flex2-row2{
                  width: 22vw;
                 height: 9vh;
                 margin: 2.5vh auto;
                 .row2-1{
                     width: 9vh;
                     height: 9vh;
                     border-radius: 9vh;
                     border: .25vw solid rgba(54,90,161,.3);
                     cursor: pointer;
                     .yuan1{
                    width: 8vh;
                     height: 8vh;  
                     border-radius: 8vh;
                      border: .3vw solid rgba(51,255,204,.5);
                      text-align: center;
                      .text{
                          font-size: 2vh;
                          color: #6acafb;
                          border-bottom: 1px solid #6acafb;
                          padding-top: .3vh;
                      }
                      .number{
                          color: #ffffff;
                          font-size: 1.7vh;
                      }
                     }
                 }
                 .row2-2{
                     margin-left: 1vw;
                 }
                 .row2-3{
                     margin-left: auto;
                     .top-flex3-two-stuats{
                         width: 8vw;
                         height: 4vh;
                         text-align: center;
                         line-height: 4vh;
                     }
                 }
             }
         }
         .top-flex3{
             width: 32vw;
         }
         .top-flex1-1{
             flex-wrap: wrap;
         .top-data{
             width: 50%;
            .flex1-list-text{
                width: 9vw;
                    font-size: 1.7vh;
                    line-height: 1vh;
                   color: #8aeaff;
                  text-align: right;
                    margin-right: 1vw;
            } 
            .text-status{
              width: 5vw;
              height: 2vh;
            }
            // .el-switch.is-disabled {
            //     opacity: 1 !important;
            // }
         }
         }
     }
     .Switchpage-con{
         width: 96vw;
         height: 21vh;
         margin-top: 2vh;
		 margin-left: 1.5vw;
         border: 1px solid rgba(0,228,255,.2);
         box-sizing: border-box;
         .con-row1{
             width: 38vw;
            height: 100%;
            border-right:1px solid rgba(0,228,255,.2);
            box-sizing: border-box;
            .con-row1-l{
            width: 5vw;
            border-right:1px solid rgba(0,228,255,.2);
            box-sizing: border-box;
            height: 100%;
            div{
                 width: 5vw;
               color: #6acafb;
               font-size: 1.7vh;
               text-align: center;
               padding-top: 4vh;
               line-height: 3.9vh;
            }
            }
            .con-row1-r{
                width: 33vw;
                text-align: center;
                .r-tit{
                    height: 4vh;
                    color: #fff;
                    font-size: 2vh;
                    line-height: 4vh;
                }
                      .Switchpage-cen-flex-list{
                 margin-bottom:1vh;
                div{
                    width: 6vw;
                    height: 3vh;
                    background-color: rgba(0,22,36,.6);
                   font-size:1.7vh;
                   color: #8aeaff;
                   margin-left:0.3vw;
                   text-align: center;
                   line-height: 3vh;
                  box-shadow: 0px 1px 0px 0px #15384c;
                  cursor: pointer;
                }
                div:nth-child(1){
                  margin-left: 0;
                }
                .Special{
                    color: #ffffff;
                    background-color: rgba(0,22,36,.9);
                    width: 6vw;
                    box-shadow: 0px 1px 0px 0px #15384c;
                }
              }
            }
         }
         .con-row2{
             width: 33vw;
             height: 100%;
             border-right:1px solid rgba(0,228,255,.2);
             box-sizing: border-box;
               .con-row1-r{
                width: 33vw;
                text-align: center;
                .r-tit{
                    height: 4vh;
                    color: #fff;
                    font-size: 2vh;
                    line-height: 4vh;
                }
                      .Switchpage-cen-flex-list{
                 margin-bottom:1vh;
                div{
                    width: 6vw;
                    height: 3vh;
                    background-color: rgba(0,22,36,.6);
                   font-size:1.7vh;
                   color: #8aeaff;
                   margin-left:0.3vw;
                   text-align: center;
                   line-height: 3vh;
                  box-shadow: 0px 1px 0px 0px #15384c;
                }
                div:nth-child(1){
                  margin-left: 0;
                }
                .Special{
                    color: #ffffff;
                    background-color: rgba(0,22,36,.9);
                    width: 6vw;
                    box-shadow: 0px 1px 0px 0px #15384c;
                }
              }
            }
         }
         .con-row3{
             width: 25vw;
             height: 100%;
                  .con-row1-r{
                width: 25vw;
                text-align: center;
                .r-tit{
                    height: 4vh;
                    color: #fff;
                    font-size: 2vh;
                    line-height: 4vh;
                }
                      .Switchpage-cen-flex-list{
                 margin-bottom:1vh;
                div{
                    width: 7.5vw;
                    height: 3vh;
                    background-color: rgba(0,22,36,.6);
                   font-size:1.7vh;
                   color: #8aeaff;
                   margin-left:0.3vw;
                   text-align: center;
                   line-height: 3vh;
                  box-shadow: 0px 1px 0px 0px #15384c;
                }
                div:nth-child(1){
                  margin-left: 0;
                }
                .Special{
                    color: #ffffff;
                    background-color: rgba(0,22,36,.9);
                    width: 7.5vw;
                    box-shadow: 0px 1px 0px 0px #15384c;
                }
              }
            }
         }
     }
     .Switchpage-bot{
         .con-row1{
             width: 27.75vw;
             height: 100%;
          .con-row1-r{
              width: 22.75vw !important;
               .Switchpage-cen-flex-list{
                   div{
                       width: 4vw !important;
                   }
               }
          }   
         }
         .con-row2{
            width: 22.75vw;
              .con-row1-r{
              width: 22.75vw !important;
               .Switchpage-cen-flex-list{
                   div{
                       width: 4vw !important;
                   }
               }
          }
         }
         .con-row3{
            width: 22.75vw;
              .con-row1-r{
              width: 22.75vw !important;
               .Switchpage-cen-flex-list{
                   div{
                       width: 7vw !important;
                   }
               }
          }
         }
          
     }
</style>